@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Quicksand:wght@300;400;500;600;700&display=swap");

@layer base {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background: transparent !important;
    transition: background 5000s ease-in;
  }

  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply transition duration-200 px-4 h-[45px] py-2 border-[1.6px] border-gray-300 font-medium w-full  bg-transparent rounded-lg  placeholder-gray-500;
  }
  [type="text"]:focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus,
  [type="text"]:focus-visible,
  [type="email"]:focus-visible,
  [type="url"]:focus-visible,
  [type="password"]:focus-visible,
  [type="number"]:focus-visible,
  [type="date"]:focus-visible,
  [type="datetime-local"]:focus-visible,
  [type="month"]:focus-visible,
  [type="search"]:focus-visible,
  [type="tel"]:focus-visible,
  [type="time"]:focus-visible,
  [type="week"]:focus-visible,
  [multiple]:focus-visible,
  textarea:focus-visible,
  select:focus-visible {
    @apply border-gray-600  shadow-none;
    --tw-ring-color: #f3f4f4 !important;
    --tw-ring-shadow: unset !important;
    outline: unset !important;
  }
  [type="text"].green:focus,
  [type="email"].green:focus,
  [type="url"].green:focus,
  [type="password"].green:focus,
  [type="number"].green:focus,
  [type="date"].green:focus,
  [type="datetime-local"].green:focus,
  [type="month"].green:focus,
  [type="search"].green:focus,
  [type="tel"].green:focus,
  [type="time"].green:focus,
  [type="week"].green:focus,
  [multiple].green:focus,
  textarea.green:focus,
  select.green:focus {
    @apply border-green-dark;
    --tw-ring-color: #107c10 !important;
    border-color: #107c10 !important;
  }
  [type="text"].red:focus,
  [type="email"].red:focus,
  [type="url"].red:focus,
  [type="password"].red:focus,
  [type="number"].red:focus,
  [type="date"].red:focus,
  [type="datetime-local"].red:focus,
  [type="month"].red:focus,
  [type="search"].red:focus,
  [type="tel"].red:focus,
  [type="time"].red:focus,
  [type="week"].red:focus,
  [multiple].red:focus,
  textarea.red:focus,
  select.red:focus {
    @apply border-red-dark;
    --tw-ring-color: #a80000 !important;
    border-color: #a80000 !important;
  }
  tbody {
    display: block;
    max-height: 320px;
    overflow: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  * {
    line-height: 1.25;
    font-style: normal;
    font-size: 16px;
  }
  svg {
    font-size: inherit;
  }

  html {
    scroll-behavior: smooth;
  }
  body {
    @apply break-words;
  }
  a {
    @apply no-underline;
  }
  h2 {
    @apply font-inter text-[25px] tablet:text-[45px] md:text-[52px] lg:text-[54px] leading-[1.1] !important;
  }

  h3 {
    @apply text-[20px] lg:text-[24px] font-semibold !important;
    line-height: 1.25;
  }
  h4 {
    @apply text-[20px] tablet:text-[22px] font-semibold !important;
    line-height: 1.25;
  }
  h5 {
    @apply font-inter text-[22px] tablet:text-[30px] font-semibold !important;
    line-height: 1.25;
  }
  h6 {
    @apply text-[16px] tablet:text-[18px] font-semibold;
    line-height: 1.25;
  }

  p {
    @apply text-[16px] font-normal font-quicksand;
    line-height: 1.3;
  }
  a {
    text-decoration: none;
    @apply text-inherit;
  }
  .horizontal_line {
    background: linear-gradient(to right, #c2c3c8, #a6a5a5);
  }
  .container {
    @apply mx-auto xxl:max-w-[1380px] tablet:max-w-[700px] md:max-w-[900px] lg:max-w-[1250px] xl:max-w-[1380px] px-6 tablet:px-0 sm:max-w-full !important;
  }
  .rounded_img {
    @apply h-[200px] w-[200px] rounded-full;
  }
  .rounded_img2 {
    @apply w-[150px] h-[150px] rounded-full;
  }
  .hwro_full {
    @apply h-full w-full rounded-full object-cover;
  }
  .card-text li {
    @apply lg:text-[16px] text-[14px] !important;
  }
  .vertical_line {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    top: 20px;
    @apply lg:top-[20px] top-[38px] p-2 lg:text-[28px] text-[18px] font-semibold;
  }
  .yellow-rounded {
    @apply ml-4 bg-yellow-light lg:h-[30px] h-[25px] w-[25px] lg:w-[30px] rounded-full;
  }
  .size {
    @apply lg:w-[200px] lg:h-[200px] w-[150px] h-[150px] rounded-full;
  }
 
  .price_btn {
    @apply bg-white px-[16px] min-w-[132px] py-2 text-black font-bold lg:text-[25px] text-[16px] rounded-[20px] center gap-2 transition-all duration-300 linear-gradient;
  }
  button.price_btn:hover,button.linear-gray:hover{
    background-position-x: 132px;
  }
  .pay-now {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    @apply lg:text-[10px] text-[8px] uppercase;
  }
  .linear-gradient {
    background: linear-gradient(to top right, #ebecf0, #f89e22, #f57901);
  }
  .linear-gray {
    background: linear-gradient(to top right, #ebecf0, #a6a5a5, #737373);
  }
  
  .text_horizontal_line {
    position: relative;
    display: inline-block;
  }
  .text_horizontal_line:before {
    content: "";
    height: 2px;
    background-color: #d35400;
    position: absolute;
    bottom: 0;
    @apply w-full;
  }
  .early-line {
    position: relative;
  }
  .early-line:before {
    content: "";
    width: 85px;
    height: 2px;
    background-color: #f89e22;
    position: absolute;
    left: -99px;
    top: 49%;
  }
  .early-line:after {
    content: "";
    width: 85px;
    height: 2px;
    background-color: #f89e22;
    position: absolute;
    right: -103px;
    top: 49%;
  }
  .icon-line {
    @apply w-[230px] h-[3px] rounded-full bg-gray-dark;
  }
  .line-through1 {
    text-decoration-line: red;
    text-decoration: line-through;
  }
}
@layer components {
  .center {
    @apply flex justify-center items-center;
  }
  .subtitle1 {
    @apply text-[19px];
  }
  .subtitle2 {
    @apply text-[21px];
  }
  .body1 {
    @apply text-[17px];
  }
  .body2 {
    @apply text-[18px];
  }

  .white-rounded {
    @apply absolute lg:top-[-45px] lg:left-[-34px] top-[-35px] left-[-23px] bg-white lg:h-[60px] lg:w-[60px] h-[46px] w-[46px] rounded-full flex justify-center items-center text-black font-bold 
          lg:text-[35px] text-[31px];
  }
  .request-btn {
    background: linear-gradient(to bottom right, #d35400, #f89e22, #d35400);
    @apply lg:py-4 lg:px-[25px] py-4 px-[20px] font-black font-inter cursor-pointer;
  }
  .btn {
    @apply h-[45px] px-10 center rounded-xl  font-semibold capitalize  transition duration-300;
  }
  .btn-btn-primary {
    @apply gap-1 bg-black h-[45px] lg:w-[320px] w-[250px] rounded-full text-white font-semibold  lg:text-[25px] text-[18px] flex justify-center items-center;
  }
  .btn-primary:hover {
    @apply text-primary-500 bg-white;
  }
  .btn-primary-outline {
    @apply border border-primary-500 text-black;
  }
  .btn-secondary {
    @apply bg-secondary-500 text-white;
  }
  .btn-secondary:hover {
    @apply text-secondary-500 bg-white;
  }
  .btn-secondary-outline {
    @apply border border-secondary-500 text-black;
  }
}
.chakra-modal__content-container {
  padding: 16px;
}
